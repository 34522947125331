.create-push-notification{
    font-family: "Rosario";
font-size:16px;
font-style: normal;
font-weight: 600;
line-height: 20px;
padding: 10px;
}
.notification-user-container{
    background-color: #F5F5F5;
    height: auto;
    width: 100%;
    border-radius: 16px;
    margin-top: 20px;
}
.user-name-image{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    padding: 10px;
    margin-bottom: 20px;
}
.notification-add-remove{
    display: flex;
    align-items: center;
    gap: 20px;
}
.user-name-image :last-child{
    font-family: "Rosario";
    font-size:16px;
    font-style: normal;
    font-weight: 600;
}

/* .create-notification-con */