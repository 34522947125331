.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    padding: 16px;
    z-index: 1; 
    /* width: 90%; */
  }
  .css-153gm7s-MuiPaper-root-MuiDrawer-paper{
    border-radius: 0px 24px 24px 0px !important; 
    border: none !important;
  }
  .css-j400xw-MuiPaper-root-MuiDrawer-paper {
   
    border-radius: 0px 24px 24px 0px !important; 
  }
  
  .welcome-text-dashboard{
    font-size: 30px;
    color: white; 
    text-align: center;
    flex-grow: 1; 
    font-family: "Rosario";
    word-break: break-all !important;
    word-wrap: break-word !important;
  }

  
  .icons {
    display: flex;
    gap: 0px !important; 
  }
  
  .c {
    width: 100%;
    height:190px !important;
    object-fit: cover;
  }
  
  .css-j400xw-MuiPaper-root-MuiDrawer-pape{
    background-color: red !important;
  
  }
  .hamburger-menu {
    display: none;
  }
  @media (max-width: 1024px) {
    .hamburger-menu {
      display: block;
    }
  
    
    .icons {
      display: flex;
    }
  }
  
  
  @media (max-width: 800px) {
    .c{
      height: 129px !important;
    }
  }
  
  @media (max-width: 480px) {
    .hamburger-menu {
      display: block;
    }
  
    .welcome-text {
      text-align: center;
      margin-left: auto;
      margin-right: auto;
    }
  
    .icons{
      display: flex;
      margin-right: 20px !important;
    }
  
  }
  
  
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    padding: 16px;
    z-index: 1; 
    margin-top: 10px;
  }
  
  .welcome-container {
    /* display: flex;
    flex-direction: column;
    align-items: center; 
     style={{ flexGrow: 0.88 }} add this inline 
     */
    width: 720px;
   
  }
  
 
  
  .home-user-account {
    font-size: 16px;
    color: white; 
    text-align: center;
  }
  
  .icons {
    display: flex;
    gap: 16px;
  }
  
  .c {
    width: 100%;
    height: 200px !important;
    object-fit: cover;
  }
  
  .hamburger-menu {
    display: none;
  }

  .whole-design-margin{
    margin: 0px 40px 10px 300px;
  }

  @media screen and (min-Width:1200px) {
    .welcome-text-dashboard{
      position: absolute;
      left: 296px;
      top: 22px;
      /* font-size:24px; */
      /* margin-left: 20px !important; */
      /* width: 100% !important; */
      /* width: 93% !important; */
  }
    
  }
  @media (max-width:1200px) {
    .welcome-text-dashboard{
        /* font-size:24px;
        margin-left: 60px; */
        /* width: 906px;
        margin: auto; */
        position: absolute;
        left: 296px;
        top: 22px;
        
    }
  }
  
  @media (max-width: 1024px) {
    .hamburger-menu {
      display: block;
    }
  
    .icons {
      display: flex;
      
    }
    .whole-design-margin{
        margin: 0px 0px 0px 100px;
 }

 .welcome-text-dashboard{
  /* font-size:24px;
  margin-left: 60px; */
  /* width: 906px;
  margin: auto; */
  position: absolute;
  left: 80px;
  top: 20px;
}

  }
  
  @media (max-width: 800px) {
    .c {
      height: 190px !important;
    }
    .whole-design-margin{
        margin-left: auto;
 }
 .welcome-text-dashboard{
    font-size:24px;
    /* margin-left: 30px; */
    /* width: 80%; */
}

  }
  
  @media (max-width: 480px) {
    .hamburger-menu {
      display: block;
    }
  
    .icons {
      display: flex;
      margin-right: -13px !important;
      gap:0px
    }
    /* .welcome-text {
      font-size: 15px !important;
    } */
    .shownotification{
      width: 250px !important;
      right: 40px !important;
    }
    .welcome-text-dashboard{
        /* font-size:20px;
        margin-left: 10px; */
        font-size: 20px;
        /* margin-left: 12px; */
        word-wrap: break-word;
        overflow-wrap: break-word;
        position: relative;
        top: 11px;
        left: 10px;
    }
  }
  
  
  .MuiListItem-root {
    cursor: pointer;
  }
 
  
  .MuiListItem-root:hover {
    /* background-color: !important; */
    box-shadow: none !important; 
    opacity: 1 !important; 
  }


  @media screen and (min-width: 800px) and (max-width: 1024px) {
    .whole-design-margin{
      margin:0px 10px 0px 10px
    }

  }
