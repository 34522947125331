.chef-section-card-container{
    display: flex;
    flex-wrap: wrap;
    max-width: 1000px;
    gap: 40px;
    transform: translateY(-80px);
}
.personal-info-chefs{
    margin-top: 20px;
    max-width: 1050px;
    padding: 20px;
}
.chef-common-input {
    width: 100%;
    border-radius: 28px;
    background-color: #f0f0f0;
    font-size: 14px;
    font-family: "Rosario";
    height: 45px;
    border: none;
    padding-left: 15px;
    outline: none;
     box-sizing: border-box;
    
  }
  .ticket-management-btn-container{
    transform: translateY(-60px);
  }
  /* @media screen and (max-width:1400px) {
    .chef-personal-card-style{

       justify-content: center;
       
    }
} */
@media screen and (max-width:1200px) {
    .chef-personal-card-style{
        gap: 20px;
        /* flex-wrap: wrap; */
        /* display: flex; */
        justify-content: center;
        width: 100% !important;
        flex-grow: 1;
    
       
    }
    .chef-details-card-container {
        justify-content: center !important;
        
     }
}

@media screen and (max-width:1024px) {
    .chef-section-card-container{
       justify-content: center;
        transform: translateY(0px);
    }
    .hr-lines{
       display: none;
    }
}

@media screen and (max-width:480px) {
    .chef-details-card-container {
       justify-content: center !important;
       
    }
    .chef-personal-card-style{
        justify-content: center;
        
     }
}
