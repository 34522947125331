.add-card-sub{
    max-width: 1920px;
/* height: 600px; */
height: auto;
flex-shrink: 0;
    border-radius: 25px;
background: #FFF;
box-shadow: 0px 2px 30px 0px rgba(0, 0, 0, 0.05);
padding: 30px;
font-family: "Rosario";
transform: translateY(-80px);
}
.susb-plan-name-container{
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.orginal-discount{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}
.add-save-subs-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 45px;
    flex-wrap: wrap;
}


.toggle-switch {
    width: 100px;
    height: 45px;
    background-color: #FFF;
    border-radius: 25px;
    display: flex;
    align-items: center;
    padding: 2px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);


  }
  
  .toggle-switch.active {
    background-color: #FFF; /* Red color for active */
  }
  
  .toggle-knob {
    width: 41px;
    height: 41px;
    background-color: #EA1D26;
    border-radius: 50%;
    transition: transform 0.3s ease;
  }
  
  .toggle-switch.active .toggle-knob {
    transform: translateX(57px);
  }

  .view-sub-page{
    font-family: "Rosario";
    display: flex;
    flex-direction: column;
    gap: 15px;
    /* margin-top: 10px; */
  }
  .Plan-name{
    color: #7E7E7E;
font-family: "Rosario";
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;
  }
  .pln-desc{
    color: #000;
font-family: "Rosario";
font-size: 22px;
font-style: normal;
font-weight: 400;
line-height: normal;
  }
@media screen and (max-width:1024px) {
    .add-card-sub{
        transform: translateY(0px);
    }
    .view-sub-page{
        padding: 10px;
    }
}

@media screen and (max-width:480px) {
    .orginal-discount{
        grid-template-columns: 1fr;
        gap: 0px;
    }
}