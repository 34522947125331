.ticket-card {
  border-radius: 25px;
  background: #fff;
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.08);
  width: 100%;
  max-width: 1920px;
  min-height: 140px;
  flex-shrink: 0;
  padding: 20px;
  display: flex;
  /* justify-content: center; */
  cursor: pointer;
}
.ticket-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.ticket-column {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.ticket-box {
  border-radius: 10px;
}
.title {
  font-family: "Rosario";
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.datess {
  color: #a4a4a4;
  font-family: "Rosario";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.ticket-description {
  font-family: "Rosario";
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}
.ticket-box {
  font-family: "Rosario";
}
.status-priority-container {
  display: flex;
  width: 400px;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
}
.chat-section-container {
  text-indent: 10px;
  margin-top: 20px;
  width: 100%;
  height: 330px;
  /* height: 55vh; */
  /* height: 400px !important; */
  flex-shrink: 0;
  border-radius: 25px;
  border: 1px solid #d8d8d8;
  background: #f9f9f9;
  overflow-y: scroll;
  flex-grow: 1;
}
.send-chat-reply-btn-container {
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
}
.chat-input {
  height: 50px;
  width: 220px;
  border-radius: 10px;
  outline: none;
  border: 1px solid #d8d8d8;
  text-indent: 10px;
  /* flex: 1; */
}
.send-btn-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: #ea1d26;
  color: #fff;
  cursor: pointer;
  /* transform: rotate(-15deg); */
}
/* Responsive Design */
@media (min-width: 801px) {
  .ticket-card {
    width: 96%;
  }
}

@media (min-width: 1600px) {
  .chat-section-container {
    height: 60vh !important;
  }
}
@media screen and (min-width:801px) and (max-width: 1200px) {
  .chat-section-container {
    /* height: 40vh !important; */
    height: 66vh;
  }
}
@media screen and (min-width:1201px) and (max-width: 1599px) {
  .chat-section-container {
    height: 55vh !important;
  }
}
@media (max-width: 800px) {
  .ticket-card {
    width: 94%;
    padding: 10px;
  }
  .ticket-container {
    padding: 20px;
  }
  .status-priority-container {
    flex-direction: column;
    width: 200px !important;
  }
  .ticket-description {
    font-size: 14px;
  }
  .chat-section-container{
    height: 44vh;
  }
}
@media (max-width: 480px) {
  .chat-section-container{
    height: 56vh;
  }
}