.admin-cards-container {
  display: flex;
  /* justify-content: space-between; */
  /* max-width: 1351px;
  gap: 20px; */
  /* max-width: 1508px; */
  max-width: 1920px;
    gap: 16px;
  /* align-items: center; */
  flex-wrap: wrap;
  padding: 16px;
  overflow: visible !important;
}
.create-btn-container {
  display: flex;
  justify-content: end;
  transform: translateY(-64px);
}
.create-admin-btn {
  /* max-width: 280px !important; */
  height: 70px !important;
  border-radius: 100px !important;
  background-color: #ea1d26 !important;
  font-family: "Rosario" !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  /* gap: 2px !important; */
  color: #fff !important;
  font-weight: 400 !important;
  text-transform: none !important;
  font-size: 16px !important;
  display: flex;
  justify-content: end;
}

/* .create-admin-btn:hover {
    background-color: #c91922 !important;
} */

.create-admin-icon {
  height: 20px !important;
  color: white !important;
}

.custom-card-admin {
  width: 310px !important;
  height: auto !important;
  flex-shrink: 0 !important;
  border-radius: 25px !important;
  background: #fff !important;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.09) !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 20px !important;
  cursor: pointer;
  transform: translateY(-40px);
}
.grid-item-charmenu {
  display: flex;
  justify-content: end;
}
.grid-item-charmenu {
  cursor: pointer;
}
.admin-info-name {
  color: #ea1d26;
  font-family: "Rosario";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-width: 100%;
  /* word-wrap: break-word; */
}
.admin-info-mail {
  font-family: "Rosario";
  color: #8c8c8c;
  font-weight: 600;
  font-size: 18px;
  /* word-break:break-all; */
  /* max-width: 100%; */
}
.admin-info-role {
  color: #343434;

  font-family: "Rosario";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.grid-item-info {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 7px;
}

.dropdown-menu-admin {
  position: absolute;
  top: 40px;
  background-color: white;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  z-index: 1000;
  border-radius: 25px;
  /* width: 200px; */
  /* height: 504px; */
  /* height: auto;*/
}
.dropdown-menu-admin ul {
  list-style: none  !important;
  padding: 10px  !important;
  margin: 0  !important;
}

.dropdown-menu-admin li {
  padding: 8px 12px !important;
  cursor: pointer  !important;
}

.dropdown-menu-admin li:hover {
  background-color: #f0f0f0;
}

.view-user-account {
  font-family: "Rosario";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #000 !important;
  /* margin-left: 10px !important; */
  padding: 10px !important;
}
.view-user-account:hover{
  background-color: #d3d3d3;
}
.delete-user-account {
  font-family: "Rosario";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #ea1d26;
  padding: 10px !important;
  /* margin-left: 10px !important; */
  
}
.delete-user-account:hover{
  background-color: #d3d3d3;
}






@media screen and (min-width:1900px) {
.custom-card-admin{
    width: 360px !important;
}
}
@media screen and (max-width:1900px) {
  .custom-card-admin{
      width: 300px !important;
  }
  }

  @media screen and (max-width:1400px) {
    .custom-card-admin{
        width: 280px !important;
        margin: auto;
    }
    }

@media screen and (max-width: 767px) {
  .admin-cards-container {
    justify-content: center;
  }
  .create-btn-container {
    padding: 10px !important;
    transform: translateY(-20px);
  }
  .custom-card-admin {
    transform: translateY(-10px);
  }
}

@media screen and (max-width: 480px) {
  .custom-card-admin {
    max-width: 260px !important;
  }
  .create-admin-btn {
    max-width: 180px !important;
    font-size: 14px !important;
    height: 60px !important;
  }
  .grid-item-info{
    width: 160%;
  }
}
