.push-notification-txt{
    font-family: "Rosario";
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 20px;
color: #120B1C;
margin-top: 20px;
padding-left:30px;
}
.whole-card-wrapper-notification{
    transform: translateY(-30px);
}

@media screen and (max-width:1024px) {
    .whole-card-wrapper-notification{
        transform: translateY(15px);
    }
}
@media screen and (max-width:800px) {
    .whole-card-wrapper-notification{
        transform: translateY(-40px);
    }
}