.chef-details-card-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  /* max-width: 980px; */
  /* justify-content: space-between; */
  flex-wrap: wrap;
  gap: 30px;
  transform: translateY(-95px);
}
.img-mail-name-container {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  margin-bottom: 20px;
}
.img-mail-name-container img{
  height: 50px;
  width: 50px;
}
.modal-txt {
  font-family: "Rosario";
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  color: #ea1d26;
  margin-bottom: 15px;
}
.modal-txt span {
  color: #000;
  font-weight: 500;
}
.modal-txt p {
  font-family: "Rosario";
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  color: #000;
}
.modal-txt p span {
  font-family: "Rosario";
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  color: #646464;
}
.date-event-details{
    color: #FFF;
    transform: translateY(-100px);
}
.chef-events-information-details{
    transform: translateY(-80px);
}
.whole-pdf{
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}
.portfoliodesign-container{
  border-radius: 10px;
  opacity: 0.6;
  background:  #C6E9FF;
  width: 174px;
height: 123px;
display: flex;
flex-direction: column;
align-items: center;
}
.pdf-logo-container{
  width: 154px;
  /* padding-top: 5px; */
  margin: 5px auto 0px auto;
height: 52px;
flex-shrink: 0;
border-radius: 10px 10px 0px 0px;
border: 4px solid #F4FBFF;
background: #FFF;
display: flex;
justify-content: center;
align-items: center;
}
.pdf-logo-container img{
  height: 40px;
  width: 40px;
}
@media screen and (max-width: 1024px) {
  .chef-details-card-container {
    justify-content: center;
    gap: 10px;
    transform: translateY(0px);
  }
  .date-event-details{
    color: #000;
    padding: 20px;
    transform: translateY(-40px);
    justify-content: center !important;
}
.chef-detail-tble-wrapper{
  margin-top: 70px;
}
}
@media screen and (max-width:800px) {
    .chef-details-card-container {
        flex-direction: column;
      justify-content: center;
      gap: 10px;
      /* transform: translateY(0px); */
      /* transform: translateY(-25px); */
      transform: translateY(-14px);
    }
    .date-event-details{
        color: #000;
        padding: 20px;
        /* transform: translateY(-20px); */
        transform: translateY(-40px);

    }
    .chef-detail-tble-wrapper{
      margin-top: 20px;
    }
   
  }
