.card2-container {
    border-radius: 25px;
    border: 1px solid rgb(247, 247, 247);
    background: #FFF;
    box-shadow: 2px 4px 20px 0px rgba(0, 0, 0, 0.04);
    padding: 20px;
    margin: 15px;
    /* max-width: 250px; */
    width: 220px;
    min-height: 100px;
    transform: translateY(-90px);
    cursor: pointer;
    
  }
  .card2-container:hover{
    border:  2px solid #EA1D26 ;
}

  @media (max-width: 768px) {
    .card2-container {
      padding: 15px;
      margin: 10px;
      transform: translateY(10px);
      margin: auto;
      border-radius: 20px;
      display: flex;
    justify-content: center;
    align-items: center;
    }
  }
  
  @media (max-width: 480px) {
    .card2-container {
      padding: 10px;
      margin: auto;
      border-radius: 15px;
    }
  }
  