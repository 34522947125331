.chef-content-card-wrapper{
    display: flex;
    flex-wrap: wrap;

}
.content-management-text{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;
    /* padding: 20px; */
    /* margin-top: 6%; */
    font-size: 22px;
    font-family: "Rosario";
}

@media screen and (max-width:1024px) {
    .chef-content-card-wrapper{
        transform: translateY(54px);
        margin:auto;
        gap: 10px;
    }
}
@media screen and (min-width:801px) and (max-width:1023px) {
    .chef-content-card-wrapper{
        /* transform: translateY(54px);
        margin:auto;
        gap: 10px; */
        display: flex;
        justify-content: center;
    }
}
@media screen and (max-width:800px) {
    .chef-content-card-wrapper{
        /* transform: translateY(10px);
        margin:auto;
        gap: 10px; */
        justify-content: center;
        transform: translateY(20px);
        /* margin: auto; */
        gap: 10px;
    }
}