.imageUploaderContainer {
    position: relative;
    display: inline-block;
  }
  
  .imageCircle {
    width: 100px;  
    height: 100px; 
    border-radius: 50%;
    border: 2px solid #EA1D26; 
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: #f0f0f0; */
    overflow: hidden;
  }
  
  .placeholderIcon {
    width: 50px; 
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .previewImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .uploadButton {
    position: absolute;
    /* bottom: -47px; */
    /* right: 10px; */
    width: 30px;
    right: 0px;
    top: 70px;
    left: 75px;
    height: 30px;
    background-color: #FFF;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
  }
  
  .fileInput {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
  