

  /* @media (max-width: 1200px) {
    .custom-card-container {
      top: 50%;
      left: 50%;
    }
  }
  @media (max-width: 768px) {
   

    .custom-card-container {
      top: 50%;
      left: 50%;
    }
}
@media (max-width: 480px) {
   
    .custom-card-container {
      top: 50%;
      left: 50%;
    }
  } */

  .card-container-accmanagement{
    /* max-width: 500px; */
    /* max-width: 810px; */
    max-width: 760px;
    transform: translateY(-80px);
  }
  @media (max-width: 1024px) {
    .card-container-accmanagement {
      margin: auto;
      transform: translateY(0px);
    }
}

  @media (max-width: 800px) {
    .card-container-accmanagement {
      padding: 10px;
      margin: auto;
      transform: translateY(0px);
    }
}