.dashboard-card {
  display: flex !important;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 850px;
  transform: translateY(-100px);
}
.graph-container {
  width: 100%;
  height: 467px;
  border-radius: 25px;
  /* border: 1px solid black; */
  padding: 10px;
  box-shadow: 2px 4px 20px 0px rgba(0, 0, 0, 0.15);

}
.report-txt-grph{
  color: #000;
  font-family: "Rosario";  
font-size: 22px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.no-of-enroll{
  color: #9B9B9B;
  font-family: "Rosario"; 
font-size: 18px;
margin-top: 4px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.txt-sample {
  text-align: right;
  font-family: "Rosario";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  margin-top: 20px;
}
/* Default for larger screens */
.custom-legend {
  display: flex;
  justify-content: flex-start; /* Horizontally aligned by default */
  margin-left: 50px;
  margin-top: -35px;
}

/* On small screens (e.g., max-width: 768px) */
@media (max-width: 768px) {
  .custom-legend {
    display: block; /* Switch to block to allow wrapping */
    margin-left: 0; /* Remove left margin */
    margin-top: 10px; /* Adjust top margin */
    text-align: center; /* Center the legend items */
  }

  .custom-legend .recharts-legend-item {
    margin-bottom: 10px; /* Add space between items */
  }
}

@media screen and (max-width: 1200px) {
  .dashboard-card {
    justify-content: space-around;
  }
  .graph-container{
    width: 98%;
  }
}

@media screen and (max-width: 800px) {
  .dashboard-card {
    justify-content: space-around;
    transform: translateY(0px);
  }
  .graph-container{
    width: 94%;
    height: 467px;
    border-radius: 25px;
    border: 1px solid black;
    /* padding: 20px; */
    /* margin: 0px 14px 0px 2px; */
    margin: 10px;
  }
}
@media screen and (max-width:480px) {
  .graph-container{
    width: 89%;
  }
  .txt-sample{
    padding-right: 10px;
  }
  .report-txt-grph{
    font-size: 16px;

  }
  .no-of-enroll{
    font-size: 12px;
  }
  .report-txt-grph {
    font-size: 1rem; /* Smaller font size for mobile */
    text-align: left; /* Center the text */
  }

  .no-of-enroll {
    font-size: 0.8rem; /* Smaller font size for mobile */
    text-align: left; /* Center the text */
  }

  /* Prevent text overlap in graph container */
  .graph-container {
    padding: 10px;
  }

  
}