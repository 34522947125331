.commision-card-container{
    display: flex;
    transform: translateY(-90px);
    flex-wrap: wrap;
    gap: 40px;
}



@media screen and (max-width: 1024px) {
  .commision-card-container {
    /* justify-content: space-around; */
    transform: translateY(0px);
    /* gap: 10px; */
  }
}
@media screen and (max-width: 576px) {
  .commision-card-container {
    justify-content: center;
    transform: translateY(0px);
    /* gap: 10px; */
  }
}