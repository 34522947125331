/* 
.FAQ-whole-container {
    max-width: 1500px;
    min-height: 140px;
    flex-shrink: 0;
    border-radius: 10px;
    background: #FFF;
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin: 15px auto;
}


.faq-btn-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
    padding: 0px 20px 0px 10px; 
    height: 50px;
    width: fit-content;
    margin-left: auto;
    width: 180px; 
   border-top: 1px solid #EEE;
border-right: 1px solid #EEE;
border-left: 1px solid #EEE;
border-radius: 10px;
}


.edit-btn, .delete-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-size: 17px;
    font-family: "Rosario";
    cursor: pointer;
}

.edit-btn {
    color: #222;
}

.delete-btn {
    color: #EA1D26;
}


.faqtitle-description-container {
    padding: 20px;
    border-radius: 10px;
    border: 1px solid #EEE;
    width: 100%;
  
    box-sizing: border-box;
}

.faq-title-text {
    color: #EA1D26;
    font-family: "Rosario";
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 8px;
}

.faq-desc-text {
    color: #222;
    font-family: "Rosario";
}

@media screen and (max-width: 800px) {
    .FAQ-whole-container {
        padding: 15px;
    }

   
} */



.FAQ-whole-container {
    max-width: 1500px;
    min-height: 140px;
    flex-shrink: 0;
    border-radius: 10px;
    background: #FFF;
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin: 0px auto; 
}

/* Button container */
.faq-btn-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
    padding: 0px 20px 0px 10px; 
    height: 50px;
    width: fit-content;
    margin-left: auto;
    width: 180px; 
   border-top: 1px solid #EEE;
border-right: 1px solid #EEE;
border-left: 1px solid #EEE;
border-radius: 10px;
}

/* Edit and Delete buttons */
.edit-btn, .delete-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-size: 17px;
    font-family: "Rosario";
    cursor: pointer;
}

.edit-btn {
    color: #222;
}

.delete-btn {
    color: #EA1D26;
}

/* Title and description container */
.faqtitle-description-container {
    padding: 20px;
    border-radius: 10px;
    border: 1px solid #EEE;
    width: 100%;
    /* margin-top: 5px; */
    box-sizing: border-box;
}

.faq-title-text {
    color: #EA1D26;
    font-family: "Rosario";
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 8px;
    word-break: break-all;
}

.faq-desc-text {
    color: #222;
    font-family: "Rosario";
    word-break: break-all;
}

/* Responsive styling */
@media screen and (max-width: 800px) {
    .FAQ-whole-container {
        padding: 15px;
    }

    /* .faq-btn-container {
        flex-direction: column;
        align-items: flex-end;
    } */
}
