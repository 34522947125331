/* SignUp.css */

.signup-container {
  /* max-width: 1920px; */
  height: 100vh;
  overflow: hidden;
}

.signup-grid {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100vh;
}

.r1 {
  width: 100%;
  height: 100%;
  border-radius: 0px 0px 0px 40px;
  object-fit: cover;
}
.left-image img {
  width: 104%;
  height: 100%;
  object-fit: cover;
}

.right-image {
  position: relative;
  z-index: 1000;
  /* top: -50px; */
}

.card-container {
  position: absolute;
  z-index: 1000;
  top: 50%;
  left: 77%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 450px;
  /* padding: 0 20px */
}
.cheflogos {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.welcome-text {
  margin-top: 10px;
  font-size: 40px;
  font-family: "Pathway Gothic One";
  font-weight: 400;
  text-align: center;
}
.custom-btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

@media (max-width: 1200px) {
  .card-container {
    top: 50%;
    left: 50%;
  }
}
@media (max-width: 768px) {
  .signup-grid {
    grid-template-columns: 1fr;
  }

  .right-image {
    display: none;
  }

  .right-image {
    top: 0;
  }

  .right-image img {
    height: 100vh;
  }
  .card-container {
    top: 50%;
    left: 50%;
  }
  .eyeicon {
    width: 20px;
    height: 20px !important;
  }
  .email-pass {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .welcome-text {
    font-size: 32px;
  }
  .card-container {
    top: 50%;
    left: 55%;
  }
}

.email-pass {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
}

.email-pass .MuiTypography-root {
  width: 100%;
  text-align: left;
}

.email-pass .MuiTextField-root {
  width: 100%;
}

.custom-btn-container {
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

