.create-modal-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ea1d26;
  color: #fff;
  border-radius: 25px;
  font-family: "Rosario" !important;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 20px;
  height: 35px;
}
