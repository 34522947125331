/* .chef-subscriptions-btn{
    display: flex;
    justify-content: space-between;
    align-items: center;
} */


.chef-subs-card{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 26%;
    gap: 20px;
}

.chef-details-card-container-subs{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  
    /* max-width: 980px; */
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 30px;
    transform: translateY(-95px);
  }


  .manage-pln{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  
    /* max-width: 980px; */
    justify-content: end;
    flex-wrap: wrap;
    gap: 30px;
    transform: translateY(-70px);
  }




@media screen and (max-width:1024px) {
       .chef-details-card-container-subs{
        flex-direction: column;
      justify-content: center;
      gap: 20px;
      /* transform: translateY(0px); */
      /* transform: translateY(-25px); */
      transform: translateY(-14px) !important;
    }
    .chef-subs-card{
        justify-content: center;
        width: 100%;
        flex-wrap: wrap;
    }

}

