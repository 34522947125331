.outer-wrapper{
    padding: 50px;
    border-radius: 25px;
background: #FFF;
box-shadow: 0px 2px 30px 0px rgba(0, 0, 0, 0.05);
max-width: 1503px;
height: auto;
transform: translateY(-70px);
flex-shrink: 0;

}

.quill {
    /* position: relative;
    align-content: center;
    align-self: center !important; */
    /* height: 50vh; */
    border-radius: none !important;
    background-color: #F0F0F0;
  }
      
  .ql-container.ql-snow {
    background-color: #F0F0F0;
    border-radius: none !important;
    border-top: 1px solid #b9b9b9 !important;
    min-height: 200px;
  }
   .ql-toolbar.ql-snow{
    background-color: #F0F0F0;
    border-radius: none !important;
    border-top: 1px solid #b9b9b9 !important;
   
   }

/* .editor-container{
   
    max-width: 1503px;

flex-shrink: 0;border-radius: 25px;
background: #FFF;
box-shadow: 0px 2px 30px 0px rgba(0, 0, 0, 0.05);
padding: 10px;
margin-bottom: 20px;
} */
/* 
.quill-editor .ql-editor {
    border-radius: 15px;
    background-color: #F0F0F0 !important;
  }
   
 
   
   
  .ql-toolbar{
    background-color: #F0F0F0;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    align-self: center;
    position: absolute;
    z-index: 100;
    bottom: 0;
    width: 100%;
  
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    padding: 15px;
    flex-wrap: wrap;
  }
   

 
  @media (max-width: 599px) and (min-width: 280px) {
    .ql-toolbar .ql-snow {
      max-width: 96.3%;
      min-width: 90%;
      padding: 8px;
      bottom: 0;
    }
   
    .ql-container.ql-snow {
      border-radius: 8px;
    }
   
    .quill-editor .ql-editor {
      border-radius: 8px;
    }
  }

  @media (min-width: 600px) and (max-width: 959px) {
    .ql-toolbar.ql-snow {
      width: 97%;
      padding: 10px;
      bottom: 0;
    }
   
    .ql-container.ql-snow {
      border-radius: 12px;
    }
   
    .quill-editor .ql-editor {
      border-radius: 12px;
    }
  }
 
  @media (min-width: 960px) and (max-width: 1279px) {
    .ql-toolbar.ql-snow {
      width: 98%;
      padding: 12px;
      bottom: 0;
    }
   
    .ql-container.ql-snow {
      border-radius: 18px;
    }
   
    .quill-editor .ql-editor {
      border-radius: 15px;
    }
  }
   
 
  @media (min-width: 1280px) and (max-width: 1919px) {
    .ql-toolbar.ql-snow {
      width: 98%;
      padding: 14px;
      bottom: 0;
    }
   
    .ql-container.ql-snow {
      border-radius: 22px;
    }
   
    .quill-editor .ql-editor {
      border-radius: 18px;
    }
  }
   
  
  @media (min-width: 1920px) {
    .ql-toolbar.ql-snow {
      width: 98.8%;
      padding: 15px;
      bottom: 0;
    }
   
    .ql-container.ql-snow {
      border-radius: 26px;
    }
   
    .quill-editor .ql-editor {
      border-radius: 22px;
    }
  }
     */
     @media screen and (max-width:1024px) {
        .outer-wrapper{
            transform: translateY(0px);
        }
     }