
.five-btn-container{
  display: flex;
  gap: 10px;
}
.section-header {
  font-family: "Rosario";
  font-size: 18px;
  /* font-weight: 400; */
}

.section-description {
  font-family: "Rosario";
  opacity: 0.7;
  color: #757575;
  margin-bottom: 10px;
  padding: 0px !important;
}
.menu-card-container {
  display: flex;
  gap: 40px;
  flex-wrap: wrap;
  max-width: 1200px;
}

.guest-menu--card {
  width: 316px;
  height: auto;
  flex-shrink: 0;
  border-radius: 20px;
  border: 1px solid #efefef;
  background-color: #fff;
  box-shadow: 0px 0px 19px 0px rgba(0, 0, 0, 0.06);
  display: flex;
  align-items: center;
  padding: 10px;
  /* flex-grow: 0;  */
}

.guest-menu--card-image {
  width: 80px;
  height: 80px;
  border-radius: 21px;
  margin-right: 10px;
}

.guest-menu--card-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.guest-menu--card-name {
  font-weight: bold;
  font-size: 16px;
  color: #000;
  font-family: "Rosario";
  word-break: break-all;
}

.guest-menu--card-description {
  font-size: 14px;
  color: #b1b1b1;
  font-family: "Rosario";
  word-break: break-all;
}

.guest-menu--card-price {
  font-weight: bold;
  font-size: 16px;
  color: #ea1d26;
  font-family: "Rosario";
  word-break: break-all;
}

.course-tabs {
  display: flex;
  justify-content: space-between;
  max-width: 280px;
  font-size: 20px;
  font-family: "Rosario";
  margin-top: 30px;
}
.course-tabs span {
  cursor: pointer;
}
.rules {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 350px;
  margin-top: 20px;
  font-family: "Rosario";
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.rules-txt {
  opacity: 0.5;
  font-family: "Rosario";
}

.guest-list-container {
  display: flex;
  /* justify-content: space-between; */
  max-width: 1600px;
  flex-wrap: wrap;
}
.guest-list-card {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 17px 0px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 343px;
  height: auto;
  display: flex;
  align-items: center;
  padding: 10px;
  margin: 10px;
}

.guest-list-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.guest-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
}

.guest-total,
.guest-id {
  font-family: "Rosario";
  margin: 2px 0;
}
.guest-name {
  font-size: 20px;
  font-weight: 600;
}

.guest-total,
.guest-id {
  opacity: 0.5;
}
.guest-image img {
  width: 40px;
  height: auto;
  object-fit: cover;
  border-radius: 50%;
}
.image-grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  max-width: 800px;
}

.image-grid-item {
  border-radius: 10px;
  overflow: hidden;
}

.image-grid-img {
  width: 190px;
  height: 190px;
}

/* Media Queries for Responsiveness */


@media screen and (max-width: 1400px) {
  .menu-card-container {
    justify-content: center;
  }
  .five-btn-container{
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 60px;
  }
}
@media screen and (max-width: 1024px) {
  .guest-list-container {
    justify-content: center !important;
  }
  .image-grid-container {
    padding: 20px;
  }
}
@media (max-width: 768px) {
  .image-grid-container {
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
  }
}
@media (max-width: 576px) {
  .responsive-btn {
    width: 120px !important; 
    height: 50px !important; 
    font-size: 14px !important; 
  }
}


@media (max-width: 480px) {
  .image-grid-container {
    grid-template-columns: repeat(1, 1fr);
    justify-items: center;
  }
  .tab-wrappers-data{
    margin-top: 5px !important;
  }
  .guest-menu--card {
      width: 270px;
  }
}