
.custom-card-container {
    position: absolute;
    z-index: 1000;
    top: 50%;
    left: 77%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 450px;
    /* padding: 0 20px */
  }
  @media (max-width: 1200px) {
    .custom-card-container {
      top: 50%;
      left: 50%;
    }
  }
  @media (max-width: 768px) {
   

    .custom-card-container {
      top: 50%;
      left: 50%;
    }
}
@media (max-width: 480px) {
   
    .custom-card-container {
      top: 50%;
      left: 50%;
    }
  }