.faq-qestion{
  font-family: "Rosario" !important;
  height: 47px;
  /* opacity: 0.6; */
}
.acc-management-text {
  margin-bottom: 8px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  text-indent: 10px !important;
  font-family: "Rosario" !important;
}
.delete-account {
  width: 180px;
  margin-top: -50px;
  font-family: "Rosario";
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #ea1d26;
  margin-bottom: 20px;
  cursor: pointer;
}
.All-color-breakcurm{
    color: white !important;
    text-decoration: none !important;
    font-family: "Rosario";
    cursor: pointer;
   
}
.all-breakcrum{
    color: white !important;
    text-decoration: none !important;
    font-family: "Rosario";
    font-size: 19px;
}
.bread-crum{
    display: flex;
    flex-wrap: wrap;
    transform: translateY(-110px);
}

@media screen and (max-width: 1024px) {
  .delete-account {
    margin-top: 20px;
    margin-left: 20px;
  }
  .bread-crum{
    /* display: none;  */
    display: flex;
    flex-wrap: wrap;
    /* margin: 11px; */
    justify-content: center;
    transform: translateY(-71px);
}
}
